'use strict';

(function($, window, document, undefined) {

	var APP = window.APP = window.APP || {};

	APP.util = {
		exec: function(controller, action) {
			var ns = APP,
				act = (action === undefined) ? 'init' : action;

			if (controller !== '' && ns[controller] && typeof ns[controller][act] === 'function') {
				ns[controller][act]();
			}
		},
		init: function() {
			var el = document.getElementById('APP'), // Update the body ID here
				controller = el.getAttribute('data-controller'),
				actions = el.getAttribute('data-action');

			APP.util.exec('common');
			APP.util.exec(controller);

			// do all the actions too.
			$.each(actions.split(/\s+/), function(i, action){
				APP.util.exec(controller, action);
			});
		}
	};

	APP.contact = (function () {

		var init = function () {
			initialize()
		};

		function initialize() {
			var map;
			var bounds = new google.maps.LatLngBounds();
			var mapOptions = {
				mapTypeId: 'roadmap',
				styles: [
					{
						'featureType': 'administrative',
						'elementType': 'labels.text.fill',
						'stylers': [
							{
								'color': '#444444'
							}
						]
					},
					{
						'featureType': 'landscape',
						'elementType': 'all',
						'stylers': [
							{
								'color': '#f2f2f2'
							}
						]
					},
					{
						'featureType': 'poi',
						'elementType': 'all',
						'stylers': [
							{
								'visibility': 'off'
							}
						]
					},
					{
						'featureType': 'road',
						'elementType': 'all',
						'stylers': [
							{
								'saturation': -100
							},
							{
								'lightness': 45
							}
						]
					},
					{
						'featureType': 'road.highway',
						'elementType': 'all',
						'stylers': [
							{
								'visibility': 'simplified'
							}
						]
					},
					{
						'featureType': 'road.arterial',
						'elementType': 'labels.icon',
						'stylers': [
							{
								'visibility': 'off'
							}
						]
					},
					{
						'featureType': 'transit',
						'elementType': 'all',
						'stylers': [
							{
								'visibility': 'off'
							}
						]
					},
					{
						'featureType': 'water',
						'elementType': 'all',
						'stylers': [
							{
								'color': '#dadada'
							},
							{
								'visibility': 'on'
							}
						]
					}
				],
			};


			var image = {
				url: "/wp-content/themes/annalaberge/assets/dist/images/icon-map.png",
				scaledSize: new google.maps.Size(56, 79),
				origin: new google.maps.Point(0, 0)
			};


			// Display a map on the page
			map = new google.maps.Map(document.getElementById("map"), mapOptions);
			map.setTilt(45);

			// Multiple Markers
			var markers = [
				['Hôpital Anna-Laberge', 45.345842, -73.763401, 1],
				['CLSC Châteauguay', 45.357947, -73.736779, 2],
				['CLSC Jardin-du-Québec', 45.192645, -73.413370, 3],
				['CLSC Kateri', 45.398841, -73.527468, 4],
				['Centre Hébergement Châteauguay', 45.353387, -73.741951, 5],
				['Centre Hébergement La Prairie', 45.421227, -73.491230, 6],
				['Centre Hébergement St-Rémi', 45.258345, -73.617738, 7],
			];

			// Display multiple markers on a map

			// Loop through our array of markers & place each one on the map
			for (i = 0; i < markers.length; i++) {
				var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
				bounds.extend(position);
				marker = new google.maps.Marker({
					position: position,
					map: map,
					title: markers[i][0],
					icon: image
				});

				// Automatically center the map fitting all markers on the screen
				map.fitBounds(bounds);
			}

			// Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
			var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function (event) {
				this.setZoom(14);
				google.maps.event.removeListener(boundsListener);
			});

		}

		return {
			init: init
		}

	})();

	APP.fondation = (function() {

		var init = function() {
			initiateGoogleMap();
		};

		var initiateGoogleMap = function(){
			var myLatLng = {lat: 45.345842, lng: -73.763401};
			var theMarker = {lat: 45.345842, lng: -73.763401};

			// Specify features and elements to define styles.
			var styleArray = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#e42053"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#e42053"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#d4e4eb"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#fef7f7"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9b7f7f"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#fef7f7"}]}];

		  // Create a map object and specify the DOM element for display.
		  var map = new google.maps.Map(document.getElementById('map'), {
		    center: myLatLng,
		    scrollwheel: false,
		    styles: styleArray,
		    zoom: 10
		  });

		  var image = {
		    url: "/wp-content/themes/annalaberge/assets/dist/images/icon-map-al.png",
		    scaledSize: new google.maps.Size(70, 85.5),
		    origin: new google.maps.Point(0, 0)
		  };

			var myarr = $('#map').data('pins').split('|');

			myarr = $.grep(myarr ,function(n){
				return(n);
			});

			$.each(myarr,function(index, value){

				var latlng = value.split(',');

				var marker = new google.maps.Marker({
				    map: map,
				    position: {lat: parseFloat(latlng[0]), lng: parseFloat(latlng[1])},
				    icon: image
				  });
			})

		  // Create a marker and set its position.
		  var marker1 = new google.maps.Marker({
		    map: map,
		    position: theMarker,
		    icon: image
		  });
		}

		return {
			init: init
		};

	})();

	APP.common = (function() {

		var mobileMenu = function(){

			var menuOuvert = false;

			$(window).scroll(function(){
				$('.mobile-menu').removeClass('mobile-menu-opened');
				$('.mobile-menu .close-button').removeClass('animated');
				$('.btn-menu-mobile').removeClass('open');
				menuOuvert = false;
			});

			$('.btn-menu-mobile').click(function(event){
				event.stopPropagation();

				if(!menuOuvert){
						$('.mobile-menu').addClass('mobile-menu-opened');

						setTimeout(function(){
					       $('.mobile-menu .close-button').addClass('animated');
					    }, 150);

						menuOuvert = true;
						$('.btn-menu-mobile').addClass('open');
				}
				else{
					$('.mobile-menu').removeClass('mobile-menu-opened');
					$('.mobile-menu .close-button').removeClass('animated');
					menuOuvert = false;
					$('.btn-menu-mobile').removeClass('open');
				}
				event.stopPropagation();
			});

			$('body').click(function(){
				if(menuOuvert){
					$('.mobile-menu').removeClass('mobile-menu-opened');
					$('.mobile-menu .close-button').removeClass('animated');
					menuOuvert = false;
					$('.btn-menu-mobile').removeClass('open');
				}
			});

		};

		var manageSubmenu = function() {

			$('header').addClass('has-submenu');

			var menu = $('#fn-sub-menu');

			$('.add-to-menu').each(function(){

				var id = $(this).attr('id');
				var title = $(this).find('h2').text();

				menu.append('<a href="#'+id+'" class="scrollto btn btn--no-bg btn--light">'+title+'</a>');
			})

			menu.append('<a href="/faire-un-don" class="btn">Faire un don</a>');

			menu.show();

			$(window).on('load scroll resize', function(){
				if($(document).width() >= 992){
					if($(document).scrollTop() > ($('header').outerHeight() - $('#fn-sub-menu').outerHeight())){
						$('header').css('padding-top', $('#fn-sub-menu').outerHeight() + 45);
						menu.addClass('fixed');
					}else{
						menu.removeClass('fixed');
						$('header').css('padding-top', 0)
					}
				}
			});
		}

		var scrollto = function() {

			$(document).on('click', '.scrollto', function(event){

				event.preventDefault();

				var section = $(this).attr('href');

				$('html, body').animate({ scrollTop:$(section).offset().top - 100 }, 800);

			});
		}

		var imgLiquid = function() {

		    $(".imgLiquidFill").imgLiquid();
		}

		var eventSlider = function() {

			$('.fn-slider-image-events').slick();

		}

		var mapCenters = function() {
			alert('yolo');
		}

		var achievementsSlider = function() {


			$('#achievements-slider').on('init afterChange', function(event, slick){
	            var slide = slick.$slider.find('.slick-current');
	            slide.addClass('slick-animate');
	        });

			var slider = $('#achievements-slider').slick({
				infinite: true,
	        	centerMode: true,
	            arrows: false,
	        	slidesToShow: 3,
	            focusOnSelect: true
			});


			var yearToDisplay = new Date().getFullYear()

			while( $('.year').index($('.year[data-year="'+yearToDisplay+'"]'))== -1 ) {
				yearToDisplay--;
			}

			var yearIndex = $('.year').index($('.year[data-year="'+yearToDisplay+'"]'))

			slider.slick('slickGoTo', yearToDisplay);


	        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
	            slick.$slider.find('.slick-animate').removeClass('slick-animate');
	        });

	        var prev_arrow = $('.achievements-slider-arrow.slick-prev');
	        var next_arrow = $('.achievements-slider-arrow.slick-next');

	        prev_arrow.click(function(){
	            slider.slick('slickPrev');
	        });

	        next_arrow.click(function(){
	            slider.slick('slickNext');
	        });

		}

		var installationsSlider = function() {


			$('#installations-slider').on('init afterChange', function(event, slick){
	            var slide = slick.$slider.find('.slick-current');
	            slide.addClass('slick-animate');
	        });

			var slider = $('#installations-slider').slick({
				infinite: true,
	        	centerMode: true,
	            arrows: false,
	        	slidesToShow: 1,
	            focusOnSelect: true
			});

	        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
	            slick.$slider.find('.slick-animate').removeClass('slick-animate');
	        });

	        var prev_arrow = $('.installations-slider-arrow.slick-prev');
	        var next_arrow = $('.installations-slider-arrow.slick-next');

	        prev_arrow.click(function(){
	            slider.slick('slickPrev');
	        });

	        next_arrow.click(function(){
	            slider.slick('slickNext');
	        });

		}

		var init = function() {
			//call functions here
			mobileMenu();
			scrollto();
			imgLiquid();

			if($('.add-to-menu').length){
				manageSubmenu();
			}

			if($('.fn-slider-image-events').length){
				eventSlider();
			}

			if($('#achievements-slider').length){
				achievementsSlider();
			}

			if($('#installations-slider').length){
				installationsSlider();
			}
		};

		return {
			init: init
		};

	})();

	$('.c-tab__title').click(function() {

		$('.c-tab__content').slideToggle(400);
	});


})(jQuery, window, document);

$(document).ready(APP.util.init);

function initMap() {
	if( $( '.page-template-template-contact #map' ).length ) {
		APP.contact.init();
	}
	if( $( '.page-template-template-fondation #map' ).length ) {
		APP.fondation.init();
	}
}

$(document).ready(function() {

	$('.js-home-slider').on('init', function (event, slick, direction) {

		// check to see if there are one or less slides
		if (!($('.js-home-slider .slick-slide').length > 1)) {

			// remove arrows
			$('.js-home-dots').hide();

		}

	});

	$('.js-home-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		appendDots: '.js-home-dots',
		arrows: false,
		slide: '.js-home-slide',
		autoplay: true,
		autoplaySpeed: 5000,
		fade: true
	});

	var vids = $('video');
    $.each(vids, function () {
        this.controls = false;
    });

    var figure = $('.l-video__item').hover(hoverVideo, hideVideo);

    function hoverVideo(e) {
        $('video', this).get(0).play();
    }

    function hideVideo(e) {
        $('video', this).get(0).pause();
	}


    //Videos lightbox
    $('.js-fullvid').on('click', function (e) {
        e.preventDefault();

        var videoId = $(this).attr('data-video');

        $('.js-video-lb').addClass('active');
        $('.js-video-lb iframe').attr('src', 'https://player.vimeo.com/video/'+ videoId +'?title=0&byline=0&portrait=0');

    })

    $('.js-close-video').on('click', function () {
        $('.js-video-lb').removeClass('active');
        $('.is-iframe').attr('src', '');
    })

    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            $('.js-video-lb').removeClass('active');
            $('.is-iframe').attr('src', '');
        }
    })
})